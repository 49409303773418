<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="userTable"
      title="LBLUSER"
      tableId="userTable"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="검진주기 저장"
            icon="save"
            @beforeAction="saveCycle"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'user-manage',
  data() {
    return {
      resetUrl: '',
      searchParam: {
        plantCd: null,
        deptCd: '',
        retireFlag: 'N'
      },
      grid: {
        columns: [
        ],
        data: [],
      },
      data: {
        userId: '',
        empNo: '',
        userName: '',
        loginId: '',
        deptCd: '',
        sexCd: null,
        mobileNo: '',
        birthDate: '',
        email: '',
        jobCd: null,
        spotCd: null,
        retireFlag: 'N',
        retireDate: '',
      },
      retireFlagItems: [
        { code: 'N', codeName: '재직' },
        { code: 'Y', codeName: '퇴직' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      isDelete: false,
      saveUrl: transactionConfig.mdm.user.cycle.url,
      saveType: 'POST',
      searchUrl: '',
      selectedUserId: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.mdm.user.list.url;

      this.saveUrl = transactionConfig.mdm.user.cycle.url;

      this.setHeader();
      this.getList();
    },
    setHeader() {
      this.$comm.getComboItems('CHECK_UP_CYCLE_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLUSER',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            required: true,
            name: 'checkupCycleCd',
            field: 'checkupCycleCd',
            label: '검진주기',
            align: 'center',
            style: 'width:120px',
            type: 'select',
            setHeader: true,
            comboItems: _result,
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ]
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveCycle() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '검진주기를 저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
